.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.states{
  padding:5px;
 margin: 10px;
  border-width:5px;
  border-color:cornsilk;
  min-width: 150px;
  color: beige;
  text-decoration: none;
  cursor: pointer;
  border-radius: 10px;
  border-style:"dotted";
  background-color: rgb(15, 14, 14);
}
.states:hover{
  border: 1px solid #010101;
  color: rgb(7, 7, 7);
  background-color: rgb(240, 234, 234);
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.myheader {
  font-size: 6vw; /* make our h1 tag larger */
  font-family: sans-serif; /* choosing our font */
  background: linear-gradient(to right, rgba(255, 215, 255, 0) 0%, rgba(225, 255, 255, 0.5) 20%, rgba(255, 255, 255, 0) 61%), linear-gradient(rgb(97, 183, 217) 52%, rgb(224, 246, 255) 60%, rgb(78, 99, 132) 61%); /* you can change the colors based on your preference */
  background-clip: text; /*it defines how far the background should extend within an element, here we set it to text */
  -webkit-background-clip: text; /*for browsers compatibility */
  -webkit-text-fill-color: transparent; /* specifies the fill color of text characters. We use transparent to use the background as our text fill  */
  animation: wave 2000ms ease alternate infinite;
  transition: all 0.4s ease;
  
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.college-container {
  padding: 10px;
  margin-left: 10%;
  margin-right: 10%;
  border: 10px solid #0ad4e7;
  border-radius: 20px;
  background-color: #eee7e7d5;
}

.college-containertwo {
  height: auto;
  padding: 10px;
  margin-left: 10%;
  margin-right: 10%;
  border: 10px solid #0ad4e7;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  
  background-color: #eee7e7d5;
}
.cenert-border{
  display: block;
  background-color: #ffffff ;
  padding: 15px;
  box-shadow: inset;
}
.college-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  cursor: pointer;
}

.college-card {
  width: calc(33.33% - 40px); /* 33.33% for 3 cards in one row, minus margin */
  margin: 20px 0; /* Added top and bottom margin */
  padding: 2px;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fafafa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.college-card:hover {
  background-color: #90dad5;
  border: 2px solid #282c34;
}

.college-card h3 {
  margin-top: 0;
}

.college-card p {
  margin: 5px 0;
}

.college-cardt {
  width: calc(85.33% - 80px); /* 33.33% for 3 cards in one row, minus margin */
  margin: 20px 0; /* Added top and bottom margin */
  padding: 2px;
  height: 550px;
  border: 1px solid #ccc;
  justify-content: center;
  justify-self: center;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.college-cardt:hover {
  background-color: #90dad5;
  border: 2px solid #282c34;
}

.college-cardt h3 {
  margin-top: 0;
}

.college-cardt p {
  margin: 5px 0;
  font-size: 30px;
}

.header_bodaerstyle {
  display: block;
  background-color: #deeaea;
  padding: 15px;
  box-shadow: inset;
  border-radius: 10px;
  border-bottom-right-radius: 0%;
  border-bottom-left-radius: 0%;
}

.silverbtn {
  width: auto;
  background-color: rgb(247, 218, 218);
  color: rgb(227, 53, 53);
  border-width: 1px;
  border-color: rgb(195, 80, 80);
  border-radius: 20px;
  font-size: 20px;
  font-style: inherit;
  padding: 5px;
}

.silver_style {
  background-color: #f3f9f9;
  border-radius: 50%;
  max-width: auto;
  height: auto;
  padding: 5px;
}

@media (max-width: 768px) {
  .college-card {
    width: calc(50% - 30px); /* 50% for 2 cards in one row, minus margin */
  }

  .college-cardt {
    width: calc(80% - 20px); /* 50% for 2 cards in one row, minus margin */
  }
}


@media (max-width: 480px) {
  .college-card {
    width: calc(100% - 20px); /* 100% for 1 card in one row, minus margin */
  }
  .college-cardt {
    width: calc(100% - 10px); /* 100% for 1 card in one row, minus margin */
  }
  .college-cardt p {
    margin: 5px 0;
    justify-content: start;
    font-size: 15px;
  }
}

.navbar-container {
  max-width: 10rem;
  overflow-x: auto; /* Add horizontal scrollbar if content overflows */
  white-space: nowrap; /* Prevent buttons from wrapping */
}
